<template>
    <div class="page">
        <to-history :prop-message="title"></to-history> 
        <div class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="osn" class="sInput" placeholder="请输入订单编号" @search="onSearch"/>
            </div>
        </div>
        <div v-infinite-scroll="getOrder" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="50">
            <div class="lists" v-for="(item,index) in data" :key="index">
                <div class="list_row">
                    <div class="list_lable" style="padding-bottom: 2rem;margin-bottom: 2rem;border-bottom: 1px solid rgba(0, 0, 0, .1);font-weight: bold;">订单编号：{{item.osn}}<span :class=" item.status=='新建' ? 'barStyB': 'barStyR' ">{{ item.status }}</span></div>
                    <div class="list_lable">客户名称：<span>{{ item.name }}</span></div>
                    <div class="list_lable">跟进人：<span>{{ item.staff_uid }}</span></div>
                    <div class="list_lable">订单金额：<span>{{ item.amount }}</span></div>
                    <div class="flex" style="background: #FFF5E9;border-radius: 10px;padding: 0 5px;margin: 2rem 0;">
                        <div style="text-align: center;font-weight: 200;"><span style="color: #FE6D1A;font-weight: bold;">{{ item.invoice_amount }}</span><br/>应收金额</div>
                        <div style="text-align: center;font-weight: 200;"><span style="color: #FE6D1A;font-weight: bold;">{{ item.payment_amount }}</span><br/>实收金额</div>
                        <div style="text-align: center;font-weight: 200;"><span style="color: #FE6D1A;font-weight: bold;">{{ item.refund_amount }}</span><br/>退款金额</div>
                        <div style="text-align: center;font-weight: 200;"><span style="color: #FE6D1A;font-weight: bold;">{{ item.replenish_amount }}</span><br/>补签金额</div>

                    </div>
                    <div class="list_lable">签订时间：<span>{{ item.sign_time }}</span></div>
                    <div class="list_lable">到期时间：<span>{{ item.end_time }}</span></div>
                    <div class="list_lable">创建时间：<span>{{ item.created }}</span></div>
                </div>
                <div class="btn_con">
                    <template>
                        <a-button style="background: #FF9100 !important;" ghost @click="showDetail(item.id)">
                            <img src="@/assets/imgs/wap/see.png" alt="">
                            查看</a-button>
                        <template v-if="item.status != '已完成' && item.status != '关闭'">
                            <a-button style="background:  #436DBB !important;" ghost @click="editOrder(item.id)">
                                <img src="@/assets/imgs/wap/edit.png" alt="">编辑</a-button>
                            <a-button
                                style="background: transparent !important; color: #436DBB;border:1px solid #436DBB;"
                                ghost @click="finish(item.id)">
                                <img src="@/assets/imgs/wap/over.png" alt="">
                                完成</a-button>
                        </template>
                    </template>
                </div>
                <div class="flex btn_con1">
                    <a-popconfirm title="确定要关闭吗?" @confirm="() => off(item.id)">
                        <a-button v-if="item.status != '已完成' && item.status != '关闭'"
                            style="border-right: 1px solid #436DBB;" ghost>关闭</a-button>
                    </a-popconfirm>
                    <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                        <a-button ghost>删除</a-button>
                    </a-popconfirm>
                </div>
                <!-- <div class="btn_con">
                    <a-button type="primary" size="small" ghost @click="showDetail(item.id)">查看</a-button>
                    <template  v-if="item.status != '已完成' && item.status != '关闭'">
                        <a-button type="primary" size="small" ghost @click="editOrder(item.id)">编辑</a-button>
                        <a-button type="primary" size="small" ghost @click="finish(item.id)">完成</a-button>
                        <a-popconfirm title="确定要关闭吗?" @confirm="() => off(item.id)">
                            <a-button type="danger" size="small" ghost>关闭</a-button>
                        </a-popconfirm>
                    </template>
                    <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                        <a-button type="danger" size="small" ghost>删除</a-button>
                    </a-popconfirm>
                </div> -->
            </div>
            <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";
import '../../../assets/css/wapList.css';
import {requestXml,getDateTime} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},
    data() {
        return {
            // 搜索的内容
            osn: "",
            orderStatus: ["关闭","新建","进行中","已完成"],
            data:[],

             // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
            title:this.$route.query.title
        };
    },
    mounted: function(){
        this.getOrder();
    },
    methods:{
        // 获取订单列表
        getOrder() {
            this.dataLoadStop = true;
            requestXml("/scrm/Order/getMyList","POST",(res) => {
                for(let i=0;i<res.list.length;i++){
                    res.list[i].sign_time = getDateTime(res.list[i].sign_time);
                    res.list[i].end_time = getDateTime(res.list[i].end_time);
                    res.list[i].created = getDateTime(res.list[i].created);
                    
                    res.list[i].status = this.orderStatus[Number(res.list[i].status)];
                }
                this.data = this.data.concat(res.list);
                if(res.page.maxpage>this.pagination.current){
                    this.pagination.current += 1
                    this.dataLoadStop = false
                }else{
                    this.dataLoadStop = true
                    this.dataLoadNomore = true
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{ "osn": this.osn,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getOrder();
        },
        // 查看
        showDetail(id){
            this.$router.push({
                path: "/scrm_wap/order/orderDetail",
                query: {
                    id: id,
                }
            });
        },
        // 编辑
        editOrder(id){
            this.$router.push({
                path: "/scrm_wap/order/orderAdd",
                query: {
                    id: id,
                }
            });
        },
        // 完成
        finish(id){
            requestXml("/scrm/Order/setDone","POST",(res) => {
                if(res == "success"){
                    this.data = [];
                    this.getOrder();
                }
            },{"id": id})
        },
        // 关闭
        off(id){
            requestXml("/scrm/Order/setClose","POST",(res) => {
                if(res == "success"){
                    this.data = [];
                    this.getOrder();
                }
            },{"id": id})
        },
        // 删除
        onDelete(id){
            requestXml("/scrm/Order/del","POST",(res) => {
                if(res == "success"){
                    this.data = [];
                    this.getOrder();
                }
            },{"id": id})
        }
        
    }
};
</script>
<style scoped>
.page {
    background-image: url('../../../assets/imgs/wap/bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    padding-bottom: 50px;
}

/deep/.ant-input {
    background: #3056BF !important;
    color: #fff;
}
/deep/ .ant-input::placeholder,
/deep/ .ant-select::placeholder {
    color: #fff;
    font-size: 12px;
    /* 将占位符文本颜色设置为灰色 */
}

/deep/ .anticon svg,
/deep/ .ant-select svg {
    color: #fff;
}

.lists {
    padding: 0;
    border-radius: 20px;
}

.list_row {
    padding: 3rem;
}

.btn_con {
    border-top: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    margin: 3rem;
    padding-top: 3rem;
}

.btn_con button {
    width: 200px;
    height: 40px;
    line-height: 18px;
    color: #fff;
    border-radius: 30px;
    border: none;
    font-size: 18px;
}

.btn_con button img {
    height: 18px;
    vertical-align: middle;
    margin-right: 5px;
}

.btn_con1 {
    background: #EDF3FF;
    border-radius: 20px;
    padding: 10px 0;
    box-sizing: border-box;
}

.btn_con1 button {
    width: 50%;
    height: 30px;
    line-height: 18px;
    color: #436DBB;
    border-radius: 0;
    border: none;
    font-size: 18px;
}
.list_lable{
    color: #666666;
}
.list_lable span{
    color: #000000;
    float: right;
}
.barStyB{
    background: #00A200;
    border-radius: 20px 0px 20px 0px;
    color: #FFFFFF !important;
    font-weight: 200;
    display: inline-block;
    padding: 0 10px;
}
.barStyR{
    background: #436DBB;
    border-radius: 20px 0px 20px 0px;
    color: #FFFFFF !important;
    font-weight: 200;
    padding: 0 10px;
}
</style>
